import React from "react";
import styled from "styled-components";
import { color } from "../theme/theme.utils";
import { Link } from "gatsby";

const StyledPageLocationContainer = styled.div`
  padding: 64px;
  background-color: ${color.gold};
`;

const StyledLinksTree = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledPageTitle = styled.h1`
  color: ${color.black};
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ isActive }) => (isActive ? color.black : color.darkGray)};
  text-underline: ${({ isActive }) =>
    isActive ? color.darkGray : color.black};
`;

const PageLocation = ({ pageName, url }) => {
  return (
    <StyledPageLocationContainer>
      <StyledLinksTree>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink isActive to={url}>
          {pageName}
        </StyledLink>
      </StyledLinksTree>
      <StyledPageTitle>{pageName}</StyledPageTitle>
    </StyledPageLocationContainer>
  );
};

export default PageLocation;
