import React from "react";
import MainLayout from "../layouts/main-layout";
import PageLocation from "../components/page-location";
import styled from "styled-components";
import { color, greaterOrEqual } from "../theme/theme.utils";

const StyledContactDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 32px;

  ${greaterOrEqual("sm")} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const StyledContactElement = styled.div`
  color: ${color.darkGray};
  margin-bottom: 16px;

  h3 {
    margin-bottom: 16px;
  }

  ${greaterOrEqual("sm")} {
    margin-bottom: 0;

    h3 {
      margin-bottom: 0;
    }
  }
`;

const StyledMapAndFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${greaterOrEqual("xl")} {
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  }
`;

const StyledForm = styled.form`
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${greaterOrEqual("xl")} {
    width: 400px;
  }

  input,
  textarea {
    font-size: 0.9rem;
    width: 100%;
    margin: 0.6rem 0;
    padding: 0.6rem;
    font-family: "Poppins", sans-serif;
    border: 1px solid ${color.gold};
    transition: 0.2s;

    &:focus {
      border: 1px solid ${color.black};
      outline: none;
    }
  }

  button {
    font-size: 1rem;
    letter-spacing: 0.1rem;
    border-radius: 50px;
    width: 120px;
    padding: 6px;
    background-color: ${color.gold};
    color: ${color.white};
    border: solid 1px ${color.gold};
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      background-color: ${color.white};
      color: ${color.gold};
    }
  }
`;

const StyledPermissionsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: left;
  align-items: flex-start;
  margin: 8px 0;

  input {
    width: 40px;
    height: 40px;
    margin: 0;
  }

  label {
    font-size: 0.8rem;
  }
`;

const onFormSubmit = (e) => {
  e.preventDefault();
  console.log("test");
};

const Kontakt = () => (
  <MainLayout>
    <PageLocation pageName="Kontakt" url="/kontakt" />
    <StyledContactDataWrapper>
      <StyledContactElement>
        <h3>Adres:</h3>
        <p>85-010 Bydgoszcz</p>
        <p>ul. Cietrzewia 1</p>
      </StyledContactElement>
      <StyledContactElement>
        <h3>Telefon:</h3>
        <p>+48 530 565 556</p>
        <p>+48 530 955 995</p>
      </StyledContactElement>
      <StyledContactElement>
        <h3>E-mail:</h3>
        <p>kontakt@camstory.pl</p>
      </StyledContactElement>
    </StyledContactDataWrapper>
    <StyledMapAndFormWrapper>
      <StyledForm onSubmit={onFormSubmit}>
        <input name="name" id="name" type="text" placeholder="Imię" required />
        <input
          name="email"
          id="email"
          type="email"
          placeholder="E-mail"
          required
        />
        <textarea
          name="message"
          id="message"
          placeholder="Wiadomość"
          rows="5"
          required
        />
        <StyledPermissionsWrapper>
          <input name="permission" id="permission" type="checkbox" required />
          <label htmlFor="permission">
            Wyrażam zgodę na przetwarzanie moich danych. Podanie danych jest
            dobrowolne, a administratorem jest Camstory 85-446 Bydgoszcz ul.
            Cietrzewia 1
          </label>
        </StyledPermissionsWrapper>
        <button type="submit">Wyślij</button>
      </StyledForm>
      <div className="map">
        <iframe
          title="contact__google-map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2393.1568749517764!2d17.91480221583078!3d53.14327907993705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47030df4d738f46f%3A0x7d00065209fd0bb!2sCietrzewia%201%2C%2085-448%20Bydgoszcz!5e0!3m2!1spl!2spl!4v1571902312682!5m2!1spl!2spl"
          width="550"
          height="550"
          frameBorder="0"
          style={{ border: "0" }}
          allowFullScreen=""
        />
      </div>
    </StyledMapAndFormWrapper>
  </MainLayout>
);

export default Kontakt;
